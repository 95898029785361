import classes from "./welcome.module.css";
import Image from "next/legacy/image";
import React from "react";
import GirlGettingAppointment from "./GirlGettingAppointment.svg";
import RegisterBusinessButton from "./RegisterBusinessButton";

const Welcome: React.FC = () => {
    return (
        <div className={`d-flex flex-wrap justify-content-center`}>
            <div
                className={`col-12 col-lg-5 mb-5 mb-lg-0 d-flex flex-column justify-content-center  text-center text-lg-start`}
            >
                <h5 className="m-0 p-0 mt-5 mt-lg-0">¡BIENVENID@!</h5>
                <h1 className={classes.title}>
                    Gestionar tus
                    <br /> turnos ahora es
                    <br />
                    <span className="text-primary">mucho más fácil.</span>
                </h1>
                <p className={classes.description}>
                    Ofrecé a tus clientes{" "}
                    <strong className="fw-bold">
                        turnos en cualquier momento
                    </strong>{" "}
                    y <strong className="fw-bold">exigí señas</strong> para
                    prevenir ausencias de la forma más sencilla y confiable 💪
                </p>
                <RegisterBusinessButton />
            </div>
            <div
                className={`
					col-12 col-lg-7
                    ps-lg-5 d-flex align-items-center
                `}
            >
                <Image
                    alt="Girl getting an appointment with tuturno"
                    src={GirlGettingAppointment}
                />
            </div>
        </div>
    );
};

export default Welcome;
