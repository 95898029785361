export const getPosition = async () => {
    try {
        const storedLatitude = localStorage.getItem("latitude");
        const storedLongitude = localStorage.getItem("longitude");

        if (storedLatitude && storedLongitude) {
            return {
                latitude: storedLatitude,
                longitude: storedLongitude,
            };
        } else {
            const ipApi = await fetch("http://ip-api.com/json");
            const ipApiData = await ipApi.json();

            localStorage.setItem("latitude", ipApiData.lat.toString());
            localStorage.setItem("longitude", ipApiData.lon.toString());

            return {
                latitude: ipApiData.lat.toString(),
                longitude: ipApiData.lon.toString(),
            };
        }
    } catch (error) {
        return null;
    }
};
